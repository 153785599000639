import { template as template_92b9f93110ec4c58993014ff234e724b } from "@ember/template-compiler";
const FKText = template_92b9f93110ec4c58993014ff234e724b(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
