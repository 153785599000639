import { template as template_1376d2a21d76467393746ec9f904fc01 } from "@ember/template-compiler";
const FKLabel = template_1376d2a21d76467393746ec9f904fc01(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
